import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';
import { getRules } from '../../../../actions/rules.actions';
import { setLoading } from '../../../../actions/system.actions';
import {
  getAddendumPdf,
  getUnit,
  updateUnit,
} from '../../../../actions/units.actions';
import NumberInput from '../../../../components/NumberInput/NumberInput';
import VppDatePicker from '../../../../components/datePicker/vppDatePicker';
import { formatValidationErrors } from '../../../../utils';
import { resendActivationLinkSchema } from '../../../../validators';
import ArchiveUnit from '../archive/ArchiveUnit';
import UnitsContactInformationBlock from './ContactInfoBlock';
import ResendActivationLink from './ResendActivationLink';
import VehiclesSection from './VehiclesSection';

const DEBOUNCE_TIME = 300;

const useStyles = makeStyles((theme) => ({
  backButtonText: {
    textTransform: 'capitalize',
    color: '#BDBDBD',
  },
  backButtonIcon: {
    fontSize: '34px!important', // TODO: Refactor needed
    left: '-8px',

    position: 'relative',
  },
  unitNumberField: {
    border: `1px solid ${theme.palette.primary.main}`,
    width: 140,
    fontSize: 20,
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  addPlusIcon: {
    color: theme.palette.primary.main,
  },
  removePlusIcon: {
    color: theme.palette.error.main,
  },
  addPlusIconDisabled: {
    color: 'gray',
  },
  unitNumberFieldInput: {
    textAlign: 'center',
    paddingTop: '2.5px',
    paddingBottom: '2.5px',
  },
}));

moment.tz.setDefault('America/Chicago');

const getExpirationDate = (guest_hours_start, guest_hours_finish, vehicle) => {
  if (!guest_hours_start || !guest_hours_finish) {
    console.log({ guest_hours_start, guest_hours_finish });
    return;
  }
  // get the hours and minutes from vehicle.expiration_date
  if (guest_hours_start === guest_hours_finish) {
    let time = moment(vehicle?.issue_date).format('H:mm').split(':');
    return moment(vehicle?.expiration_date)
      .set({ hours: time[0], minutes: time[1] })
      .toDate();
  } else {
    let time = guest_hours_finish.split(':');
    return moment(vehicle?.expiration_date)
      .set({ hours: time[0], minutes: time[1] })
      .toDate();
  }
};

const EditUnits = ({ match }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const system = useSelector((state) => state.system);
  const rules = useSelector((state) => state.rules);
  const { enqueueSnackbar } = useSnackbar();

  const [isArchived, setIsArchived] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [tenantVehicles, setTenantVehicles] = useState([]);
  const [guestVehicles, setGuestVehicles] = useState([]);
  const [reservedSpots, setReservedSpots] = useState([]);
  const [status, setStatus] = useState('');
  const [disabledAddendum, setDisabledAddendum] = useState(false);
  const [newActivationData, setNewActivationData] = useState({});

  const history = useHistory();

  const {
    errors,
    register,
    setValue,
    getValues,
    handleSubmit,
    control,
    watch,
    clearErrors,
    reset,
    unregister,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
  });
  const { unitId } = match.params;
  const { guest_hours_finish, guest_hours_start } = rules;

  const getData = useCallback(() => {
    window.scrollTo(0, 0);
    dispatch(setLoading(true));
    dispatch(getUnit(unitId))
      .then(({ data }) => {
        setDisabledAddendum(data.status !== 'active');
        setIsArchived(data.status === 'archived');
        Object.keys(data).forEach((item) => {
          setValue(item, data[item]);
          if (item === 'tenant_vehicles_number') {
            setTenantVehicles([...new Array(data[item]).fill('ok')]);
            return;
          }
          if (item === 'daily_guest_vehicles_number') {
            setGuestVehicles([...new Array(data[item]).fill('ok')]);
            return;
          }
          if (item === 'status') {
            setStatus(data[item]);
            return;
          }
          if (item === 'reserved_spots') {
            setReservedSpots(data[item]);
          }
          if (
            item === 'first_name' ||
            item === 'last_name' ||
            item === 'phone_number' ||
            item === 'email' ||
            item === 'unit_number'
          ) {
            setNewActivationData((prev) => ({
              ...prev,
              [item]: data[item],
            }));
          }
        });
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [dispatch, isEditMode]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    dispatch(getRules());
  }, [dispatch]);

  // ON HOLD FOR FUTURE NOTICE
  // const statusSelect = ['active', 'blocked', 'suspended'].map((i) => ({
  //   name: i,
  //   value: i,
  // }));

  const handleDownloadAddemdumPdf = () => {
    dispatch(getAddendumPdf(unitId)).then(({ data }) => {
      window.open(data.parking_addendum, '_blank');
    });
  };

  const handleAddTenant = () => {
    const currentVehicles = watch('tenant_vehicles') || [];
    const newVehicle = {
      id: '',
      status: 'pending',
      issue_date: new Date(),
      expiration_date: moment().add(1, 'years').endOf('day').toDate(),
      licence_state: '',
      licence_plate: '',
      make: '',
      model: '',
      color: '',
    };

    const filteredVehicles = currentVehicles.filter((vehicle) => {
      return ('licence_plate' in vehicle)
    })

    // Update form state with new vehicle
    setValue('tenant_vehicles', [...filteredVehicles, newVehicle], {
      shouldValidate: true,
      shouldDirty: true,
    });

    // Update UI state
    setTenantVehicles((vehicles) => [...vehicles, 'ok']);
  };

  const handleAddGuest = () => {
    const currentVehicles = watch('guest_vehicles') || [];
    const newVehicle = {
      id: '',
      status: 'pending',
      issue_date: new Date(),
      expiration_date: getExpirationDate(guest_hours_start, guest_hours_finish),
      licence_state: '',
      licence_plate: '',
      make: '',
      model: '',
      color: '',
    };

    const filteredVehicles = currentVehicles.filter((vehicle) => {
      return ('licence_plate' in vehicle)
    })

    // // Update form state with new vehicle
    setValue('guest_vehicles', [...filteredVehicles, newVehicle], {
      shouldValidate: true,
      shouldDirty: true,
    });

    // // Update UI state
    setGuestVehicles((vehicles) => [...vehicles, 'ok']);
  };

  const handleDeleteVehicle = async (id, type) => {
    // Get the current list of vehicles
    const currentVehicles = watch()[`${type}_vehicles`] || [];

    // First, unregister all fields to prevent stale data
    unregister(`${type}_vehicles`);

    // Create new array without the deleted vehicle
    const updatedVehicles = currentVehicles.filter((item, index) => {
      if (!('licence_plate' in item) && !('id' in item)) {
        return false;
      }
      return index !== id
    });

    // Explicitly set each vehicle's data to preserve all fields
    await Promise.all(
      updatedVehicles.map(async (vehicle, index) => {
        const fields = [
          'id',
          'status',
          'issue_date',
          'expiration_date',
          'licence_state',
          'licence_plate',
          'make',
          'model',
          'color',
        ];

        await Promise.all(
          fields.map((field) =>
            setValue(`${type}_vehicles[${index}].${field}`, vehicle[field], {
              shouldValidate: true,
              shouldDirty: true,
            })
          )
        );
      })
    );

    // Update UI state
    const typedHook = type === 'tenant' ? setTenantVehicles : setGuestVehicles;
    typedHook(new Array(updatedVehicles.length).fill('ok'));

    // Force a form state update
    setValue(`${type}_vehicles`, updatedVehicles, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleChangeVehicleNumber = useCallback(
    debounce((count, type) => {
      const typedHook =
        type === 'tenant' ? setTenantVehicles : setGuestVehicles;
      const vehicles = type === 'tenant' ? tenantVehicles : guestVehicles;

      const vehiclesLength = vehicles.filter(
        (vehicle) => vehicle !== undefined
      ).length;

      if (count > vehiclesLength) {
        typedHook((vehicles) => {
          return [...vehicles, ...new Array(count - vehiclesLength).fill('ok')];
        });
      } else if (vehiclesLength > count) {
        typedHook((vehicles) => {
          const newArray = [...vehicles];
          newArray.splice(-(vehiclesLength - count));
          return newArray;
        });
      }
    }, DEBOUNCE_TIME),
    [setGuestVehicles, setTenantVehicles, tenantVehicles, guestVehicles]
  );

  const handleChangeTenantNumber = (e) => {
    const count = parseInt(e.target.value, 10);
    handleChangeVehicleNumber(count, 'tenant');
  };

  const handleChangeGuestNumber = (e) => {
    const count = parseInt(e.target.value, 10);
    handleChangeVehicleNumber(count, 'guest');
  };

  const tenantVehiclesLength = () =>
    tenantVehicles.filter((vehicle) => vehicle !== undefined).length;

  const guestVehiclesLength = () =>
    guestVehicles.filter((vehicle) => vehicle !== undefined).length;

  useEffect(() => {
    setValue('tenant_vehicles_number', tenantVehiclesLength());
    setValue('daily_guest_vehicles_number', guestVehiclesLength());
  }, [tenantVehicles, guestVehicles, setValue, isEditMode]);

  const onSubmit = useCallback(
    (data) => {
      setFieldErrors({});

      data.tenant_vehicles =
        data.tenant_vehicles?.filter((vehicle) => vehicle.issue_date) ||
        data.tenant_vehicles;

      data.guest_vehicles =
        data.guest_vehicles?.filter((vehicle) => vehicle.issue_date) ||
        data.guest_vehicles;

      data.guest_vehicles =
        data.guest_vehicles?.map((vehicle) => {
          vehicle.expiration_date = getExpirationDate(
            guest_hours_start,
            guest_hours_finish,
            vehicle
          );
          if (
            !vehicle.licence_plate ||
            !vehicle.color ||
            !vehicle.make ||
            !vehicle.model ||
            !vehicle.licence_state
          )
            vehicle.expiration_date = '';
          return vehicle;
        }) || data.guest_vehicles;

      data.tenant_vehicles =
        data.tenant_vehicles?.map((vehicle) => {
          if (
            !vehicle.licence_plate ||
            !vehicle.color ||
            !vehicle.make ||
            !vehicle.model ||
            !vehicle.licence_state
          ) {
            vehicle.expiration_date = '';
          } else {
            vehicle.issue_date = moment(vehicle.issue_date);
          }
          return vehicle;
        }) || data.tenant_vehicles;

      try {
        resendActivationLinkSchema.parse(data);

        setSubmitDisabled(true);
        dispatch(updateUnit(unitId, { ...data, issue_date: null }))
          .then(() => {
            enqueueSnackbar('Unit updated successfully', {
              variant: 'success',
            });
            setIsEditMode(false);
            rootRef.current.scrollIntoView({ behavior: 'smooth' });
          })
          .catch(({ response }) => {
            const { data } = response;
            setFieldErrors(data);
            if (data.no_spot_space) {
              enqueueSnackbar(data.no_spot_space, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar(
                'Error updating unit. Please make sure all fields are filled in correctly.',
                {
                  variant: 'error',
                }
              );
            }
            rootRef.current.scrollIntoView({ behavior: 'smooth' });
          })
          .finally(() => {
            setSubmitDisabled(false);
          });
      } catch (err) {
        if (err instanceof z.ZodError) {
          window.scrollTo(0, 0);
          setFieldErrors(formatValidationErrors(err));
        }
      }
    },
    [guest_hours_finish, guest_hours_start]
  );

  return (
    <Box mr={4} pb={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        ref={rootRef}
      >
        <Button
          onClick={() => {
            history.goBack();
          }}
          startIcon={
            <ArrowBack color="primary" className={classes.backButtonIcon} />
          }
        >
          <Typography color="textSecondary" className={classes.backButtonText}>
            Back
          </Typography>
        </Button>
        <Box display="flex" alignItems="center">
          {!isArchived ? (
            <>
              <ArchiveUnit
                unitId={unitId}
                renderOpen={(handleClickOpen) => (
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Archive
                  </Button>
                )}
              />
              <Box ml={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isEditMode}
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </Button>
              </Box>
            </>
          ) : !system.loading ? (
            <Box>
              <Typography variant="h6" color="primary">
                Archived
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box mt={2} mx={-4}>
        <Divider />
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box my={3}>
          <Box display="flex" alignItems="center" mb={3} pl={3}>
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography color="primary" variant="h6">
                  Unit number
                </Typography>
              </Box>
              <TextField
                name="unit_number"
                size="small"
                inputRef={register}
                error={!!fieldErrors.unit_number}
                helperText={
                  fieldErrors.unit_number ? fieldErrors.unit_number : null
                }
                InputProps={{
                  classes: {
                    root: classes.unitNumberField,
                    input: classes.unitNumberFieldInput,
                  },
                  readOnly: !isEditMode,
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" ml={4}>
              <Box mr={2}>
                <Typography>Issue date</Typography>
              </Box>
              <VppDatePicker control={control} name="issue_date" disabled />
            </Box>
            {status === 'pending' && (
              <ResendActivationLink
                unitId={unitId}
                newActivationData={newActivationData}
                onSuccess={getData}
              />
            )}
            {/* <Box display="flex" alignItems="center" ml={4}>
              <Box mr={2}>
                <Typography color="primary" variant="h6">
                  Status
                </Typography>
              </Box>
              <VppSelect
                name="status"
                size="medium"
                control={control}
                options={statusSelect}
                disabled={!isEditMode}
              />
            </Box> */}
          </Box>

          <UnitsContactInformationBlock
            inputRefs={register}
            fieldErrors={fieldErrors}
            disabled={!isEditMode}
          />

          <Grid
            container
            style={{ padding: 20 }}
            alignItems="center"
            my={3}
            columns={3}
            direction={'row'}
            spacing={2}
          >
            <Grid item spacing={3} xs={4}>
              <Box display="flex" alignItems="center" mr={4}>
                <Box mr={5} pl={3}>
                  <Typography variant="subtitle2" color="primary">
                    Permitted Vehicles:
                  </Typography>
                </Box>
                <NumberInput
                  name="tenant_vehicles_number"
                  size="small"
                  disabled={!isEditMode}
                  onChange={handleChangeTenantNumber}
                  inputRef={register}
                  error={!!fieldErrors.tenant_vehicles_number}
                  helperText={
                    fieldErrors.tenant_vehicles_number
                      ? fieldErrors.tenant_vehicles_number
                      : null
                  }
                />
              </Box>
            </Grid>
            <Grid item spacing={3} xs={4}>
              <Box display="flex" alignItems="center">
                <Box mr={5}>
                  <Typography variant="subtitle2" color="primary">
                    Permitted daily guest vehicles:
                  </Typography>
                </Box>
                <NumberInput
                  name="daily_guest_vehicles_number"
                  size="small"
                  disabled={!isEditMode}
                  onChange={handleChangeGuestNumber}
                  inputRef={register}
                  error={!!fieldErrors.daily_guest_vehicles_number}
                  helperText={
                    fieldErrors.daily_guest_vehicles_number
                      ? fieldErrors.daily_guest_vehicles_number
                      : null
                  }
                />
              </Box>
            </Grid>
            <TenantReservedSpots
              register={register}
              fieldErrors={fieldErrors}
              reservedSpots={reservedSpots}
              isEditMode={isEditMode}
              setValue={setValue}
              getValues={getValues}
              control={control}
            />
          </Grid>

          <VehiclesSection
            type="tenant"
            disabled={!isEditMode}
            title="Permitted tenant vehicles"
            control={control}
            register={register}
            vehicles={tenantVehicles}
            fieldErrors={fieldErrors}
            vehicleTitle="Vehicle"
            addButtonText="ADD TENANT PERMIT"
            addButtonHandler={handleAddTenant}
            deleteButtonHandler={handleDeleteVehicle}
            watch={watch}
            setValue={setValue}
            formErrors={errors}
          />

          <VehiclesSection
            type="guest"
            title="Permitted daily guest vehicles"
            disabled={!isEditMode}
            control={control}
            register={register}
            vehicles={guestVehicles}
            fieldErrors={fieldErrors}
            vehicleTitle="Guest Vehicle"
            addButtonText="ADD GUEST PERMIT"
            addButtonHandler={handleAddGuest}
            deleteButtonHandler={handleDeleteVehicle}
            watch={watch}
            setValue={setValue}
            formErrors={errors}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              size="large"
              startIcon={<CloudUploadIcon />}
              onClick={() => handleDownloadAddemdumPdf()}
              disabled={disabledAddendum}
            >
              PARKING ADDENDUM
            </Button>
            {!isArchived ? (
              <Box display="flex" alignItems="center">
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    onClick={() => {
                      setIsEditMode(false);
                      setFieldErrors({});
                      reset();
                      getData();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={!isEditMode || submitDisabled}
                    onClick={(e) => {
                      clearErrors();
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </form>
    </Box>
  );
};

const TenantReservedSpots = ({
  register,
  fieldErrors,
  reservedSpots,
  isEditMode,
  control,
  getValues,
  setValue,
}) => {
  const [reservedSpotsCount, setReservedSpotsCount] = useState([]);
  const rules = useSelector((state) => state.rules);
  const theme = useTheme();
  const classes = useStyles(theme);
  const { allow_tenant_to_own_reserved_spots } = rules;
  const handleAddSpot = () => {
    setReservedSpotsCount([...reservedSpotsCount, 'ok']);
  };
  const handleRemoveSpot = (index) => {
    let count = reservedSpotsCount.filter((_, i) => i !== index);
    const data = getValues();
    if (data.reserved_spots.length === 0 || count.length === 0) {
      setValue('reserved_spots', [{ id: '', spot: '' }]);
      setReservedSpotsCount(['ok']);
      return;
    }
    setReservedSpotsCount(count);
    setValue(
      'reserved_spots',
      index === 0
        ? [...data.reserved_spots.slice(1)]
        : [
            ...data.reserved_spots.slice(0, index),
            ...data.reserved_spots.slice(index + 1),
          ].filter((value) => value.spot)
    );
  };
  useEffect(() => {
    setReservedSpotsCount(
      reservedSpots.length > 0 ? reservedSpots.map(() => 'ok') : ['ok']
    );
  }, [allow_tenant_to_own_reserved_spots, reservedSpots]);

  return !allow_tenant_to_own_reserved_spots
    ? null
    : reservedSpotsCount.length > 0
    ? reservedSpotsCount.map((item, index) => (
        <>
          <Grid item key={index} xs={4}>
            <Box display="flex" alignItems="center">
              <Box mr={5} pl={3}>
                <Typography variant="subtitle2" color="primary">
                  Reserved Spot:
                </Typography>
              </Box>
              <TextField
                name={`reserved_spots[${index}].id`}
                defaultValue={
                  reservedSpots[index] ? reservedSpots[index].id : ''
                }
                size="small"
                style={{ display: 'none' }}
                inputRef={register}
              />
              <TextField
                name={`reserved_spots[${index}].spot`}
                size="small"
                defaultValue={
                  reservedSpots[index] ? reservedSpots[index].spot : ''
                }
                disabled={!isEditMode}
                inputRef={register}
                error={!!fieldErrors.reserved_spots?.[index]}
                InputProps={{
                  endAdornment: isEditMode ? (
                    <InputAdornment>
                      <IconButton
                        aria-label="Remove reserved spot"
                        onClick={() =>
                          isEditMode ? handleRemoveSpot(index) : null
                        }
                        edge="end"
                      >
                        <CancelRoundedIcon
                          fontSize="small"
                          className={
                            isEditMode
                              ? classes.removePlusIcon
                              : classes.addPlusIconDisabled
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                autoComplete="off"
                helperText={
                  fieldErrors.reserved_spots?.[index]
                    ? fieldErrors.reserved_spots?.[index]
                    : null
                }
                style={{ maxWidth: '40%' }}
              />
              {index === reservedSpotsCount.length - 1 && (
                <Box justifySelf={'center'}>
                  <Button
                    type="button"
                    disabled={!isEditMode}
                    onClick={handleAddSpot}
                  >
                    <AddCircleIcon
                      className={
                        isEditMode
                          ? classes.addPlusIcon
                          : classes.addPlusIconDisabled
                      }
                    />
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </>
      ))
    : null;
};

export default EditUnits;
